<template>
  <b-container class="login">
    <b-img v-if="$root.$i18n.locale === 'en'" :src="enLogo" class="logo" />
    <b-img v-else :src="jaLogo" class="logo" />
    <GoogleLogin
      :params="params"
      :render-params="renderParams"
      :on-success="onSignInSuccess"
      :on-failure="onSignInFailure"
    />
  </b-container>
</template>

<script lang="ts">
import GoogleLogin from 'vue-google-login';
import { mapState } from 'vuex';

import jaLogo from '@/assets/ja-logo.svg';
import enLogo from '@/assets/en-logo.svg';

export default {
  name: 'Login',
  components: {
    GoogleLogin,
  },
  data() {
    return {
      params: {
        client_id:
          '267943453777-99qfoi92aauf3iidfiq0ir209juumdoc.apps.googleusercontent.com',
        fetch_basic_profile: false,
        scope: 'email profile openid',
        hosted_domain: 'hennge.com',
      },
      jaLogo,
      enLogo,
      // only needed if you want to render the button with the google ui
      renderParams: {
        longtitle: true,
        theme: 'dark',
      },
    };
  },
  computed: {
    ...mapState(['authStatus']),
  },
  methods: {
    onSignInSuccess() {
      // do nothing
    },
    onSignInFailure() {
      // do nothing
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/sass/views/Login.scss';
</style>
