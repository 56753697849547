
{
  "en": {
    "Hunters": "Hunters",
    "today": "today",
    "day ago": "0 day ago | one day ago | {count} days ago",
    "week ago": "0 week ago | one week ago | {count} weeks ago",
    "month ago": "0 month ago | one month ago | {count} months ago",
    "year ago": "0 year ago | one year ago | {count} years ago",
    "unknown date": "unknown date",
    "Online": "Held online"
  },
  "ja": {
    "Hunters": "ハンター",
    "today": "今日",
    "day ago": "0 日前 | 1 日前 | {count} 日前",
    "week ago": "0 週間前 | 1 週間前 | {count} 週間前",
    "month ago": "0 ヶ月前 | 1 ヶ月前 | {count} ヶ月前",
    "year ago": "0 年前 | 1 年前 | {count} 年前",
    "unknown date": "日付不明",
    "Online": "オンラインで"
  }
}
