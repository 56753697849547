<template>
  <b-navbar
    :class="
      scrollTop || $route.path !== '/' ? 'navbar-custom' : 'navbar-custom-short'
    "
    fixed="top"
  >
    <b-navbar-brand active to="/">
      <b-img
        v-if="!scrollTop && $route.path === '/'"
        :src="Logo"
        class="site-icon-scrolled"
        @click="scrollToTop"
      />
      <b-img
        v-else-if="$root.$i18n.locale === 'en'"
        :src="enLogo"
        class="site-icon-scrolltop"
      />
      <b-img v-else :src="jaLogo" class="site-icon-scrolltop" />
    </b-navbar-brand>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item to="/">
          {{ $t('home') }}
        </b-nav-item>
        <!-- <b-nav-item to="/report">
            {{ $t("new report") }}
          </b-nav-item> -->
        <b-nav-item to="/about">
          {{ $t('about') }}
        </b-nav-item>
      </b-navbar-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item
          class="create-restaurant-icon"
          @click="$bvModal.show('create-restaurant-modal')"
        >
          <CreateRestaurantModal @created="onRestaurantCreated" />
        </b-nav-item>
        <b-nav-item-dropdown class="navbar-dropdown" right>
          <template #button-content>
            <font-awesome-icon icon="globe" size="lg" />
          </template>
          <b-dropdown-item-button @click="onLanguageChange('en')">
            English
          </b-dropdown-item-button>
          <b-dropdown-item-button @click="onLanguageChange('ja')">
            日本語
          </b-dropdown-item-button>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown
          id="new-feat-popover"
          class="navbar-dropdown"
          right
        >
          <template #button-content>
            <b-img
              :src="userLogoWithFallback"
              alt="user_profile"
              rounded="circle"
              thumbnail
              @error="fallbackUserLogo = blankAvatar"
            />
          </template>
          <b-dropdown-item-button
            v-if="me && me.inGIPLunch"
            @click="$router.push('/profile/my-lunches')"
          >
            {{ $t('My Lunches') }}
          </b-dropdown-item-button>
          <b-dropdown-item-button
            v-if="me && me.inGIPLunch"
            @click="openSubmitReportModal"
          >
            {{ $t('Report a virtual lunch') }}
          </b-dropdown-item-button>
          <b-dropdown-item-button @click="$refs.googleLogout.$el.click()">
            <GoogleLogin
              ref="googleLogout"
              :params="params"
              :logout-button="true"
              :on-success="onSignOut"
              :on-failure="onSignOut"
            >
              {{ $t('logout') }}
            </GoogleLogin>
          </b-dropdown-item-button>
          <b-popover
            target="new-feat-popover"
            placement="bottom"
            :show="!seenNewFeat"
            :disabled="seenNewFeat"
            triggers="click blur"
            >{{
              $t("Now you can read reports from lunches you've had!")
            }}</b-popover
          >
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
    <SubmitReportModal />
  </b-navbar>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import GoogleLogin from 'vue-google-login';
import debounce from 'lodash/debounce';
import { newApolloClient } from '@/vue-apollo';
import CreateRestaurantModal from '@/components/CreateRestaurantModal.vue';
import SubmitReportModal from '@/components/SubmitReportModal.vue';

import Logo from '@/assets/filled-riceball.svg';
import jaLogo from '@/assets/ja-logo.svg';
import enLogo from '@/assets/en-logo.svg';
import blankAvatar from '@/assets/blank_avatar.jpg';

import Me from '@/graphql/Me.gql';

export default {
  components: {
    GoogleLogin,
    CreateRestaurantModal,
    SubmitReportModal,
  },
  data() {
    return {
      jaLogo,
      enLogo,
      Logo,
      blankAvatar,
      windowHeight: 0,
      count: 0,
      params: {
        client_id:
          '267943453777-99qfoi92aauf3iidfiq0ir209juumdoc.apps.googleusercontent.com',
        fetch_basic_profile: false,
        scope: 'email profile openid',
        hosted_domain: 'hennge.com',
      },
      height: Math.min(60, window.innerHeight * 0.1),
      scrolledHeight: Math.min(60, window.innerHeight * 0.05),
      scrollTop: true,
      fallbackUserLogo: null,
    };
  },
  apollo: {
    // Pages
    me: {
      // GraphQL Query
      query: Me,
    },
  },
  computed: {
    ...mapState(['authStatus', 'seenNewFeat']),
    userLogoWithFallback() {
      return (
        this.fallbackUserLogo ??
        this.me?.employee?.slack_user?.avatar ??
        blankAvatar
      );
    },
  },
  created() {
    window.addEventListener('resize', this.onResize);
    this.handleDebouncedScroll = debounce(this.handleScroll, 100);
    window.addEventListener('scroll', this.handleDebouncedScroll);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('scroll', this.handleDebouncedScroll);
  },
  methods: {
    ...mapActions(['setIsNewLunchVirtual']),
    onSignOut() {
      // do nothing
    },
    onResize() {
      this.height = Math.min(60, window.innerHeight * 0.1);
    },
    async onLanguageChange(toLanguage) {
      this.$root.$i18n.locale = toLanguage;
      localStorage.setItem('Preferred_locale', toLanguage);
      const apolloClient = newApolloClient(toLanguage);
      this.$apollo.provider.defaultClient = apolloClient;
    },
    handleScroll() {
      this.scrollTop = document.documentElement.scrollTop < 50;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    onRestaurantCreated() {
      this.$root.$bvModal.msgBoxOk(
        `${this.$t(
          'Thank you! You have successfully added a restaurant to Gohunter.'
        )}
        🍙.`,
        {
          id: 'success-msg-modal',
          size: 'lg',
          headerClass: 'p-2 border-bottom-0',
          bodyClass: 'success-msg',
          modalClass: 'no-footer-modal',
          centered: true,
        }
      );
      setTimeout(() => {
        this.$root.$bvModal.hide('success-msg-modal');
      }, 3000);
    },
    openSubmitReportModal() {
      this.setIsNewLunchVirtual(true);
      this.$bvModal.show('submit-report-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/sass/components/Navbar.scss';
</style>

<i18n>
{
  "en": {
    "home": "Home",
    "new report": "Submit a Report",
    "about": "About",
    "logout": "Logout",
    "Thank you! You have successfully added a restaurant to Gohunter.": "Thank you! You have successfully added a restaurant to Gohunter.",
    "Now you can read reports from lunches you've had!": "Now you can read reports from lunches you've had!",
    "My Lunches": "My Lunches",
    "Report a virtual lunch": "Report a virtual lunch"
  },
  "ja": {
    "home": "ホーム",
    "new report": "レポートを送信",
    "about": "概要",
    "logout": "ログアウト",
    "Thank you! You have successfully added a restaurant to Gohunter.": "レストラン登録は成功しました。ありがとうございます！",
    "Now you can read reports from lunches you've had!": "参加したGIPランチのレポートを確認できるようになりました！",
    "My Lunches": "マイランチ",
    "Report a virtual lunch": "オンラインランチを報告する"
  }
}
</i18n>
