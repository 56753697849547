<template>
  <div>
    <LoadingIcon
      v-if="loading"
      class-name="loading-icon-big"
      class="container-centered"
    />
    <LunchReports v-else-if="!!myLunches.length" :lunches="myLunches" />
    <h1 v-else>
      {{ $t('You have not participated in any GIP lunches yet') }}&nbsp;🕶️
    </h1>
  </div>
</template>

<script>
import LunchReports from '@/components/LunchReports.vue';
import MyLunches from '@/graphql/MyLunches.gql';
import LoadingIcon from '@/components/LoadingIcon.vue';

export default {
  name: 'MyLunches',
  components: {
    LunchReports,
    LoadingIcon,
  },
  computed: {
    loading() {
      return this.$apollo.queries.myLunches.loading || !this.myLunches;
    },
  },
  apollo: {
    myLunches: {
      // GraphQL Query
      query: MyLunches,
      update: (data) => (data.me ? data.me.my_lunches : undefined),
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/sass/base/_variables.scss';
</style>

<i18n>
{
  "en": {
    "You have not participated in any GIP lunches yet": "You have not participated in any GIP lunches yet"
  },
  "ja": {
    "You have not participated in any GIP lunches yet": "まだGIPランチ参加していません"
  }
}
</i18n>
