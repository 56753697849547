
{
  "en": {
    "Show More": "Show More",
    "Show Less": "Show Less"
  },
  "ja": {
    "Show More": "詳細表示",
    "Show Less": "詳細閉じる"
  }
}
