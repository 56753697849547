
{
  "en": {
    "division": "Division",
    "joined": "Joined",
    "short description": "Short Description"
  },
  "ja": {
    "division": "ディビジョン",
    "joined": "入社",
    "short description": "簡単な自己紹介"
  }
}
