
{
  "en": {
    "I'm Feeling Indecisive": "I'm Feeling Indecisive",
    "Search restaurants": "Search restaurants",
    "Oops... No results found.": "Oops... No results found."
  },
  "ja": {
    "I'm Feeling Indecisive": "決められない！",
    "Search restaurants": "店を検索",
    "Oops... No results found.": "あっ！結果が見つかりません。"
  }
}
