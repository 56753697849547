
{
  "en": {
    "p1": "We all love Shibuya, don’t we? There are so many great restaurants near our office. It’s one of the perks working for Hennge. However, we also understand you get choice paralysis (first world problems!) By using the data of past GIP lunches, Gohunter helps you find good food near our office.",
    "p2": "Our mission is to encourage more interactions between employees by making the arrangement of GIP lunches and you daily lunches with coworkers easier and more fun! Found an interesting restaurant in Gohunter that you want to go? Shoot it to your colleague! They probably would want to try too. Tired of convenience store food but don’t know where to go? Let Gohunter choose it for you!",
    "p3": "ゴハンター (Gohunter), is a combination of the words ごはん (meals) and ハンター (hunter).",
    "Site Developers": "Site Developers",
    "h2-brand": "Gohunter... ",
    "h2-what": "What?",
    "laurence_joined": "June, 2019",
    "laurence_description": "I don't eat natto, durian, stinky tofu or anything a thousand years old. Stop trying to get me to eat your weird food. Bug reports to Michelle please.",
    "michelle_joined": "November, 2018",
    "michelle_description": "I eat natto, durian, thousand-year-old egg, stinky tofu. Feel free to challenge me with any kind of exotic food you can think of 😉 Lunch invitations are welcome. Bug reports to Laurence please.",
    "rob_description": "TBD",
    "rob_joined": "July 2022",
    "simon_description": "TBD",
    "simon_joined": "2022"
  },
  "ja": {
    "p1":"皆んな渋谷好きなんでしょう？オフィス辺りに美味しいレストランがいっぱい！HENNGEで働くのいいところの一つだけど、選択肢がありすぎて決められなくなっちゃう！過去の「GIPランチ」のデータを活用して、「ゴハンター」は近くの美味しいレストランを紹介します。",
    "p2": "GIPランチでも、普段の仲間ランチでも、ゴハンターを使えばアレンジ簡単なので、もっと社員交流しましょう！ゴハンターで見たレストラン行ってみたいと思えば同僚誘っちゃえ！皆んなそう思ってるはず。コンビニ弁当はもううんざりだけどどこにすればいいかわからない時はぜひゴハンターへ！",
    "p3": "ゴハンターは「ご飯」と「ハンター」（借主）のコンビネーションです。",
    "Site Developers": "開発者",
    "h2-brand": "ゴハンター",
    "h2-what": "って何？！",
    "laurence_joined": "2019年6月",
    "michelle_joined": "2018年11月",
    "rob_description": "TBD",
    "rob_joined": "2022年7月",
    "simon_description": "TBD",
    "simon_joined": "2022年"
  }
}
