
{
  "en": {
    "home": "Home",
    "new report": "Submit a Report",
    "about": "About",
    "logout": "Logout",
    "Thank you! You have successfully added a restaurant to Gohunter.": "Thank you! You have successfully added a restaurant to Gohunter.",
    "Now you can read reports from lunches you've had!": "Now you can read reports from lunches you've had!",
    "My Lunches": "My Lunches",
    "Report a virtual lunch": "Report a virtual lunch"
  },
  "ja": {
    "home": "ホーム",
    "new report": "レポートを送信",
    "about": "概要",
    "logout": "ログアウト",
    "Thank you! You have successfully added a restaurant to Gohunter.": "レストラン登録は成功しました。ありがとうございます！",
    "Now you can read reports from lunches you've had!": "参加したGIPランチのレポートを確認できるようになりました！",
    "My Lunches": "マイランチ",
    "Report a virtual lunch": "オンラインランチを報告する"
  }
}
