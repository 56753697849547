
{
  "en": {
    "Address": "Address",
    "Name": "Name",
    "Phone number": "Phone number",
    "Google Rating": "Google Rating",
    "Website": "Website",
    "GIP Lunch": "GIP lunch | GIP lunches",
    "No tags": "No tags added yet",
    "Add a new tag": "Add a new tag",
    "Nonexistent Error": "Oops, the restaurant does not exist.",
    "Save": "Save",
    "Lunches": "Lunches",
    "There have been no GIP lunches at this restaurant": "There have been no GIP lunches at this restaurant",
    "Report": "Report"
  },
  "ja": {
    "Address": "住所",
    "Name": "店名",
    "Phone number": "電話番号",
    "Google Rating": "グーグル 格付け",
    "Website": "ウェブサイト",
    "GIP Lunch": "GIP ランチ",
    "No tags": "まだタグがありません",
    "Add a new tag": "タグを追加する",
    "Nonexistent Error": "お探しのお店は見つかりませんでした",
    "Save": "保存",
    "Lunches": "ランチ",
    "There have been no GIP lunches at this restaurant": "このお店はGIPランチでは使用されたことがありません",
    "Report": "投稿"
  }
}
