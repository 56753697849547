import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { createI18n, castToVueI18n } from 'vue-i18n-bridge';
import messages from '@intlify/unplugin-vue-i18n/messages';
import browserLanguage from 'in-browser-language';

const locale =
  localStorage.getItem('Preferred_locale') ||
  browserLanguage.pick(['en', 'ja'], 'en');

Vue.use(VueI18n, { bridge: true });

export default castToVueI18n(
  createI18n(
    {
      legacy: false,
      locale: locale,
      silentTranslationWarn: true,
      missingWarn: false,
      messages: messages,
    },
    VueI18n
  )
);
