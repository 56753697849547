
{
  "en": {
    "Home": "Home",
    "Language": "Language",
    "About": "About",
    "logout": "Logout",
    "Now you can read reports from lunches you've had!": "Now you can read reports from lunches you've had!",
    "My Lunches": "My Lunches"
  },
  "ja": {
    "Home": "ホーム",
    "Language": "言語",
    "About": "概要",
    "logout": "ログアウト",
    "Now you can read reports from lunches you've had!": "参加したGIPランチのレポートを確認できるようになりました！",
    "My Lunches": "マイランチ"
  }
}
