
{
  "en": {
    "Thank you! You have successfully added a restaurant to Gohunter.": "Thank you! You have successfully added a restaurant to Gohunter.",
    "More Actions": "More Actions"
  },
  "ja": {
    "Thank you! You have successfully added a restaurant to Gohunter.": "レストラン登録は成功しました。ありがとうございます！",
    "More Actions": "その他操作"
  }
}
