
{
  "en": {
    "filters": "Filters",
    "apply": "Apply",
    "cancel": "Cancel",
    "clear": "Clear"
  },
  "ja": {
    "filters": "フィルター",
    "apply": "適応",
    "cancel": "キャンセル",
    "clear": "クリア"
  }
}
