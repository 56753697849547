
{
  "en": {
    "Submit a GIP Lunch Report": "Submit a GIP Lunch Report",
    "When?": "When?",
    "When was the lunch?": "When was the lunch?",
    "Where?": "Where?",
    "In the cloud (virtual)": "In the cloud (virtual)",
    "Enter or select all other participants...": "Enter or select all other participants...",
    "Tell us about your dining experience with the intern.": "Tell us about your dining experience with the intern.",
    "Reset": "Reset",
    "Submit": "Submit",
    "Thank you! You have successfully submitted a lunch report via Gohunter": "Thank you! You have successfully submitted a lunch report via Gohunter.",
    "There was an error. Please try again later.": "There was an error. Please try again later."
  },
  "ja": {
    "Submit a GIP Lunch Report": "GIPランチレポートの投稿",
    "When?": "いつ？",
    "When was the lunch?": "ランチはいつでしたか？",
    "Where?": "店は?",
    "In the cloud (virtual)": "オンライン",
    "Enter or select all other participants...": "他のすべてのメンバーを入力または選択してください...",
    "Tell us about your dining experience with the intern.": "インターンとのランチの印象を教えてください。",
    "Reset": "リセット",
    "Submit": "投稿",
    "Thank you! You have successfully submitted a lunch report via Gohunter": "ご投稿ありがとうございました。",
    "There was an error. Please try again later.": "エラーが発生しました。しばらくしてからもう一度お試しください。"
  }
}
