
{
    "en": {
        "Add a New Restaurant": "Add a New Restaurant",
        "Details":"Details",
        "Submit": "Submit",
        "Cannot find details for the place you selected.": "Cannot find details for the place you selected.",
        "This place is not recognized on Google Maps.":"This place is not recognized on Google Maps.",
        "The place you selected is not a restaurant.": "The place you selected is not a restaurant.",
        "This restaurant is already in Gohunter.": "This restaurant is already in Gohunter.",
        "Search for a restaurant by name or find it on the map.": "Search for a restaurant by name or find it on the map.",
        "Search by restaurant name": "Search by restaurant name"
    },
    "ja": {
        "Add a New Restaurant": "レストランを追加する",
        "Details":"詳細",
        "Submit": "登録",
        "Cannot find details for the place you selected.": "選択された店の詳細は見つかりません。",
        "This place is not recognized on Google Maps.":"このレストランはGoogle Mapsに登録されていません。",
        "The place you selected is not a restaurant.":"選択された店はレストランではありません。",
        "This restaurant is already in Gohunter.": "このレストランはすでに登録されています。",
        "Search for a restaurant by name or find it on the map.": "名前で検索するか、地図で選択してください。",
        "Search by restaurant name": "名前で検索"
    }
}
