import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
// import { restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client';
import browserLanguage from 'in-browser-language';

// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
export const AUTH_TOKEN = 'apollo-token';

const locale =
  localStorage.getItem('Preferred_locale') ||
  browserLanguage.pick(['en', 'ja'], 'en');

export function newApolloClient(language: string): ApolloClient<NormalizedCacheObject> {
  const httpLink = new HttpLink({ uri: import.meta.env.VITE_GRAPHQL_HTTP });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem(AUTH_TOKEN);
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const localeLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        'Accept-Language': language,
      },
    };
  });

  const apolloClient = new ApolloClient({
    link: authLink.concat(localeLink).concat(httpLink),
    cache: new InMemoryCache(),
  });

  return apolloClient;
}

// Call this in the Vue app file
export function createProvider(options = {}) {
  const apolloClient = newApolloClient(locale);

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      },
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log(
        '%cError',
        'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
        error.message
      );
    },
  });

  return apolloProvider;
}

// Manually call this when user log in
export async function onLogin(apolloClient: ApolloClient<NormalizedCacheObject>, token: string) {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
  // if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.clearStore();
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message);
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient: ApolloClient<NormalizedCacheObject>) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN);
  }
  // if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.clearStore();
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message);
  }
}
