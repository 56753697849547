<template>
  <b-col class="col-md-4">
    <b-card no-body>
      <b-card-img
        :src="'https://cdn.dribbble.com/users/2140642/screenshots/4301537/rodrigosloader.gif'"
        top
        :height="`${imageHeight}`"
      />
      <div class="card-text-custom">
        ▒▒▒▒ ▒▒▒▒ ▒▒▒▒<font-awesome-icon
          icon="utensils"
          size="lg"
          style="margin: 0 0.3em"
        />▒
      </div>
    </b-card>
  </b-col>
</template>

<script>
export default {
  props: {
    imageHeight: {
      default: 166,
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/sass/components/RestaurantPreview.scss';
</style>
